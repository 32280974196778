@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;800&family=Rubik:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Inter:wght@300;400;500;700&display=swap');
@tailwind base;

@layer base {
	html {
		@apply bg-bgNeutral-background dark:bg-bgNeutral-dark;
	}
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-family: 'Rubik', sans-serif;
		font-weight: 700;
	}
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p,
	i,
	span {
		@apply text-textNeutral-dark;
	}
	h1 {
		@apply text-[21px] md:text-[28px];
	}
	h2 {
		@apply text-[17px] md:text-[20px];
	}
	h3 {
		@apply text-[15px] md:text-base;
	}
	h4 {
		@apply text-[14px];
	}
	h5 {
		@apply text-[13px];
	}
	p,
	a,
	span,
	ul,
	li,
	ol,
	button,
	td,
	th,
	tr,
	div {
		font-family: 'Inter', sans-serif;
		font-weight: 400;
	}
	p,
	span,
	ul,
	li,
	ol,
	button,
	td,
	th,
	tr {
		@apply text-textNeutral-dark;
	}

	p {
		@apply text-[15px];
	}

	p {
		line-height: 1.5em;
	}

	th,
	td,
	tr {
		@apply text-[13px];
	}

	.container input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
	}

	/* Create a custom radio button */
	.checkmark {
		position: absolute;
		top: 50%;
		left: 1em;
		height: 16px;
		width: 16px;
		background-color: #f5f6f8;
		border-radius: 50%;
		transform: translateY(-50%);
	}

	/* When the radio button is checked, add a blue background */
	.radio-input:checked ~ .checkmark:before {
		display: block !important;
	}
	.radio-input:checked ~ .checkmark:after {
		display: block !important;
	}

	.checkmark:before {
		z-index: 1;
		width: 100%;
		height: 100%;
		top: 50%;
		left: 50%;
		content: '';
		position: absolute;
		display: none;
		transform: translate(-50%, -50%);
		border-radius: 50%;
	}

	/* Create the indicator (the dot/circle - hidden when not checked) */
	.checkmark:after {
		z-index: 2;
		width: 6px;
		height: 6px;
		top: 50%;
		left: 50%;
		content: '';
		position: absolute;
		display: none;
		transform: translate(-50%, -50%);
		border-radius: 50%;
	}

	/* Show the indicator (dot/circle) when checked */
	.radio-input:checked ~ .checkmark:after {
		display: block;
	}

	.radio-input .checkmark:before {
		top: 50%;
		left: 50%;
		width: 100%;
		height: 100%;
		border-radius: 50%;
		transform: translate(-50%, -50%);
	}

	/* Style the indicator (dot/circle) */
	.radio-input .checkmark:after {
		top: 50%;
		left: 50%;
		width: 6px;
		height: 6px;
		border-radius: 50%;
		background: white;
		transform: translate(-50%, -50%);
	}

	/* Quill editor tag styles in preview card */
	.quill-editor-theme---preview {
		overflow: hidden;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 3;
	}

	.ql-toolbar {
		border: 0px solid transparent !important;
		border-bottom: 1px solid #333;
		border-top-left-radius: 25px !important;
		border-top-right-radius: 25px !important;
	}

	.ql-container {
		border: 0px solid transparent !important;
		border-bottom-left-radius: 25px !important;
		border-bottom-right-radius: 25px !important;
	}

	.quill-editor-theme p {
		color: rgb(68 68 68 / 0.72);
		font-size: 13px !important;
		line-height: 1.5em !important;
	}

	.quill-editor-theme a {
		color: #2d77ff;
		text-decoration: underline;
	}

	.quill-editor-theme ul {
		padding-left: 27px;
		list-style: circle;
	}

	.quill-editor-theme ol {
		padding-left: 31px;
		list-style: decimal;
	}

	.quill-editor-theme ul li {
		color: rgb(68 68 68 / 0.72);
		font-size: 13px !important;
	}

	.quill-editor-theme ol li {
		color: rgb(68 68 68 / 0.72);
		font-size: 13px !important;
	}

	.toggle:checked + .slider {
		background-color: #39cc93;
	}

	.toggle:focus + .slider {
		box-shadow: 0 0 1px #39cc93;
	}

	.toggle:checked + .slider:before {
		-webkit-transform: translateX(20px);
		-ms-transform: translateX(20px);
		transform: translateX(20px);
	}
}

@tailwind components;

@tailwind utilities;
